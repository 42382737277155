<script>
	import { GameHeader } from "da-components/v0";
	import { fancyTimeFormat , getParsedValue} from "../services/utils";
	import { coinCounterBindStore, coinAnimationStore, exitModalStore, sound, gameTipsStore } from "../store";
	import { onDestroy, onMount } from "svelte";
	import { playSound } from "../services/audio";
	import { dbGameSessionRoundValue, dbGameTimer } from "../services/database";
	export let score;
	export let time;
	export let isHost = true;
	let isSoundOn = true;
	let hostRoundController = false;
	let bindCoinCounter;
	let coinCounterTimeout;
	let showModal = false;

	const unsubscribeCoinAnimationStore = coinAnimationStore.subscribe(val => {
		if (bindCoinCounter)
			coinCounterBindStore.set({
				left: bindCoinCounter.getBoundingClientRect().left,
				top: bindCoinCounter.getBoundingClientRect().top,
			});
	});

	const exitModalStoreUnsubscribe = exitModalStore.subscribe(val => {
		showModal = val;
	});

	let showTipsLocal = localStorage.getItem("da-wos-show-tips") || true;
	showTipsLocal = getParsedValue(showTipsLocal);

	gameTipsStore.set(showTipsLocal);
	onMount(() => {
		bindCoinCounter = document.querySelector("#coins-counter-svg");
		coinCounterTimeout = setTimeout(() => {
			if (bindCoinCounter)
				coinCounterBindStore.set({
					left: bindCoinCounter.getBoundingClientRect().left,
					top: bindCoinCounter.getBoundingClientRect().top,
				});
		}, 1000);
	});

	onDestroy(() => {
		clearTimeout(coinCounterTimeout);
		unsubscribeCoinAnimationStore();
		exitModalStoreUnsubscribe();
	});

	$: isTipsOn = $gameTipsStore;
	$: soundState = $sound;
	$: if (soundState === false) {
		isSoundOn = false;
		playSound("MUTE");
	} else {
		isSoundOn = true;
		playSound("UNMUTE");
	}

	

	const soundButtonHandler = isSoundOn => {
		if (isSoundOn === true) {
			isSoundOn = true;
			sound.update(() => true);
			playSound("CLICK");
		} else if (isSoundOn === false) {
			playSound("CLICK");
			isSoundOn = false;
			sound.update(() => false);
		}
	};

	const getTime = time => {
		if (time !== undefined && time >= 0) {
			return fancyTimeFormat(time);
		} else if (time <= 0) {
			return "00:00";
		} else return "02:30";
	};

	const endRoundonClick = () => {
		console.log('endRoundClick');
		exitModalStore.set(true);
	};

	const toggleTips = () => {
		console.log('toggle');
		if (isTipsOn) {
			gameTipsStore.set(false);
			localStorage.setItem("da-wos-show-tips", false);
		} else {
			gameTipsStore.set(true);
			localStorage.setItem("da-wos-show-tips", true);
		}
	};
</script>

<GameHeader
	noOfCoins={score}
	gameNameSvg="./images/wos-header-logo.svg"
	{isHost}
	{soundButtonHandler}
	{isSoundOn}
	time={getTime(time)}
	{endRoundonClick}
	themeSupported={false}
	onMouseDown={() => playSound("CLICK")}
	showTips={true}
	{toggleTips}
	{isTipsOn}
	startRoundToolTips5SecondsText="If know how to play Words of Pirate. Turn this button off"
/>

<style>
</style>
