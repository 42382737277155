<script lang="ts">
	import { afterUpdate, onDestroy, onMount } from "svelte";
	import { SplashScreen } from "da-components/v0";
	import ChooseMode from "./pages/ChooseMode.svelte";
	import Notification from "./components/Notification.svelte";
	import GameScreen from "./pages/GameScreen.svelte";
	import LeaderboardScreen from "./pages/LeaderboardScreen.svelte";
	import LobbyScreen from "./pages/LobbyScreen.svelte";
	import TimerScreen from "./pages/TimerScreen.svelte";
	import { playSound } from "./services/audio";
	import "./services/database";
	import {
		dbGameSessionRounds,
		dbGameSessionRoundValue,
		dbHost,
		dbPage,
		dbUsers,
		getRoundValue,
		listenOnFirebaseKey,
		setRoundValue,
		user,
	} from "./services/database";
	import { getParams } from "./services/utils";
	import { usersStore } from "./store";
	import { info } from "./services/Notifier";
	let page;
	let users = [];
	let usersArray = [];
	let usersOnlineStatus = [];
	let hostId;
	let dbPageKey;
	let innerWidth;
	let showSplashScreen3Seconds = true;
	let showSplashScreen3SecondsTimeout;
	let showedSplashScreenOnce = false;
	let hostAction = {};
	let hostName;
	let dbHostActionRefKey;
	// let isLoaded;
	let isHost = getParams("isHost") === "true";
	$: isHost = hostId === user.id;
	// $: console.log(page);

	listenOnFirebaseKey(dbHost, val => {
		if (hostId) {
			const oldHostName = users.find(user => user.id === hostId)?.userName.split(" ")[0];
			const newHostName = users.find(user => user.id === val)?.userName.split(" ")[0];
			let message = "";
			if (val === getParams("userId")) {
				message = `${oldHostName || "Old Host"} has left the game and you are the new host!`;
			} else {
				if (newHostName) {
					message = `${oldHostName || "Old Host"} has left the game and new host is ${newHostName}!`;
				} else {
					message = `${oldHostName || "Old Host"} has left the game and new host has been assigned!`;
				}
			}
			info(message, "HostDisconnected", 5000);
		}
		hostId = val;
	});

	$: {
		if (hostAction && hostId !== user?.id && Date.now() - hostAction["time"] <= 5000) {
			let action = hostAction["action"];
			let notification;
			if (action === "End Round") {
				notification = `${hostName}(Host) has ended the round.`;
			}
			if (notification) {
				info(notification, "HostAction", 5000);
			}
		}
	}

	$: {
		if (users) {
			usersArray = [];
			for (const id in users) {
				let currUser = users[id];
				usersArray.push(currUser);
				if (currUser.isOnline === false) {
					if (id in usersOnlineStatus && usersOnlineStatus[id] == true) {
						if (users[id].id === user.id) {
							info(
								"You have been disconnected, please check your internet connection!",
								"Disconnected",
								5000
							);
						} else {
							info(`${users[id]["userName"].split(" ")[0]} is disconnected!`, "Disconnected", 5000);
						}
					}
					usersOnlineStatus[id] = false;
				} else if (currUser.isOnline === true) {
					if (id in usersOnlineStatus && usersOnlineStatus[id] === false) {
						if (users[id].id === user.id) {
							info("You are reconnected!", "Reconnected", 5000);
						} else {
							info(`${users[id]["userName"].split(" ")[0]} is reconnected!`, "Reconnected", 5000);
						}
					}
					usersOnlineStatus[id] = true;
				}
			}
		}
	}

	$: {
		if (users.length > 1 && hostId !== undefined) {
			hostName = users.find(el => el.id === hostId).userName.split(" ")[0];
		}
	}

	function onPageChange(snap) {
		if (!snap.exists() || snap.val() === null) {
			page = "SPLASH_SCREEN";
			clearTimeout(showSplashScreen3SecondsTimeout);
			showSplashScreen3Seconds = true;
			showSplashScreen3SecondsTimeout = setTimeout(async () => {
				showSplashScreen3Seconds = false;
				showedSplashScreenOnce = true;
				if (page === "SPLASH_SCREEN") {
					await dbPageKey.set("LOBBY_SCREEN");
				}
			}, 3000);
			return;
		}

		page = snap.val();
		clearTimeout(showSplashScreen3SecondsTimeout);
		showSplashScreen3Seconds = false;
		if (showedSplashScreenOnce === false && page !== "SPLASH_SCREEN") {
			clearTimeout(showSplashScreen3SecondsTimeout);
			showSplashScreen3Seconds = true;
			showSplashScreen3SecondsTimeout = setTimeout(() => {
				showSplashScreen3Seconds = false;
				showedSplashScreenOnce = true;
			}, 3000);
		}
	}

	const onHostAction = snap => {
		if (!snap.exists() || snap.val() === null) {
			return;
		}
		hostAction = snap.val();
	};

	if (isHost) {
		dbHost
			.get()
			.then(snap => {
				if (!snap.val()) {
					dbHost.set(user.id);
				}
			})
			.catch(err => console.log("call from here", err));
	}

	$: {
		if (user.id === hostId) {
			dbHost.onDisconnect().remove();
		}
	}

	onMount(() => {
		listenOnFirebaseKey(dbGameSessionRoundValue, val => {
			if (!val) {
				return;
			}
			setRoundValue(val);
			if (dbPageKey) {
				dbPageKey.off("value", onPageChange);
			}
			if (dbHostActionRefKey) {
				dbHostActionRefKey.off("value");
			}
			dbHostActionRefKey = dbGameSessionRounds.child(`${getRoundValue()}`).child("hostAction");
			dbHostActionRefKey.on("value", onHostAction);
			dbPageKey = dbGameSessionRounds.child(`${getRoundValue()}`).child("page");
			dbPageKey.on("value", onPageChange);
		});

		listenOnFirebaseKey(dbUsers, val => {
			usersStore.set(val);
			users = Object.values(val);
		});
	});
	onDestroy(() => {
		clearTimeout(showSplashScreen3SecondsTimeout);
		dbGameSessionRoundValue.off("value");
		dbUsers.off("value");
		playSound("MUTE");
	});
	afterUpdate(() => {
		if (page === "SPLASH_SCREEN" || page === "LOBBY_SCREEN" || page === "CHOOSE_MODE") {
			playSound("BGMUSIC");
		} else if (
			page === "TIMER" ||
			page === "GAME" ||
			page === "WINNER_PAGE" ||
			page === "LEADERBOARD"
		) {
			playSound("BGMUSICSTOP");
		}
	});
</script>


<svelte:window bind:innerWidth />

<main>
	{#if showSplashScreen3Seconds === false}
		{#if page === "SPLASH_SCREEN"}
			<SplashScreen
				{isHost}
				svgSrc={"./images/backgrounds/wooden-background.svg"}
				bgColor={"#8A6C4D"}
				logoSrc={"./images/wos-splash-logo.svg"}
				subHeading={"⛴ Let your Words take you to Treasure ⚓️"}
			/>
		{:else if page === "LOBBY_SCREEN"}
			<LobbyScreen {isHost} {hostId} {hostName} />
		{:else if page === "CHOOSE_MODE"}
			<ChooseMode {isHost} {hostName} />
		{:else if page === "TIMER"}
			<TimerScreen {isHost} />
		{:else if page === "GAME"}
			<GameScreen {isHost} {innerWidth} />
		{:else if page === "LEADERBOARD"}
			<LeaderboardScreen {isHost} {hostName} />
		{/if}
	{:else}
		<SplashScreen
			{isHost}
			svgSrc={"./images/backgrounds/wooden-background.svg"}
			bgColor={"#8A6C4D"}
			logoSrc={"./images/wos-splash-logo.svg"}
			subHeading={"⛴ Let your Words take you to Treasure ⚓️"}
		/>
	{/if}
	<Notification />
</main>

<style>
	:global(body, html) {
		color: white;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		background-color: #2b2038;
		overflow: hidden;
		font-family: "Pally";
	}

	:global(*) {
		box-sizing: border-box;
	}

	:root {
		--base: hsl(23, 40%, 39%);
		--base-l-25: hsl(23, 40%, 54%);
		--base-l-60: hsl(23, 40%, 76%);
		--base-d-60: hsl(23, 40%, 16%);
		font-family: "Pally";
	}
	/* @media screen and (max-width: 1000px) {
		:global(html) {
			font-size: 18px;
		}
	}
	@media screen and (max-width: 900px) {
		:global(html) {
			font-size: 16px;
		}
	}
	@media screen and (max-width: 800px) {
		:global(html) {
			font-size: 14px;
		}
	}
	@media screen and (max-width: 700px) {
		:global(html) {
			font-size: 12px;
		}
	} */
	main {
		width: 100vw;
		height: 100vh;
		position: relative;
		background-position: center;
		background-size: cover;
	}
</style>
