<script>
	import { notification } from "../store";
	import { onDestroy } from "svelte";
	import DisconnectedSvg from "../icons/DisconnectedSvg.svelte";
	import ReconnectedSvg from "../icons/ReconnectedSvg.svelte";
	import HostChanged from "../icons/HostChanged.svelte";
	import HostAction from "../icons/HostAction.svelte";
	import { Notification } from "da-components/v0";

	export let themes = {
		danger: "#bb2124",
		success: "#22bb33",
		warning: "#f0ad4e",
		info: "#5E576A",
		default: "#aaaaaa",
	};

	export let themesColors = {
		danger: "rgba(195, 80, 80, 1)",
		success: "rgba(59, 170, 110, 1)",
		default: "black",
		warning: "#f0ad4e",
		info: "#5E576A",
	};
	export let timeout = 5000;
	let count = 0;
	let toasts = [];
	let unsubscribe;

	function createToast(msg, theme, to, typeOfNotification) {
		const background = themes[theme] || themes["default"];
		const color = themesColors[theme] || themesColors["default"];
		toasts = [
			{
				id: count,
				msg,
				background,
				timeout: to || timeout,
				width: "100%",
				typeOfNotification,
				color,
			},
			...toasts,
		];
		count = count + 1;
	}
	unsubscribe = notification.subscribe(value => {
		if (!value) {
			return;
		}
		createToast(value.message, value.type, value.timeout, value.typeOfNotification);
		notification.set();
	});
	onDestroy(unsubscribe);
	function removeToast(id) {
		toasts = toasts.filter(t => t.id != id);
	}
</script>

<ul class="toasts">
	{#each toasts as toast (toast.id)}
		<Notification {toast} {removeToast} />
	{/each}
</ul>

<style>
	:global(.toasts) {
		list-style: none;
		position: fixed;
		bottom: 0;
		right: 0;
		padding: 0;
		margin: 0;
		z-index: 9999;
	}
	.toasts > :global(.toast) {
		position: relative;
		margin: 1vh 1vw;
		min-width: 30vw;
		position: relative;
		animation: animate-in 600ms forwards;
		color: #fff;
		border-radius: 10px;
		max-width: 40vw;
	}

	@keyframes animate-in {
		0%,
		60%,
		75%,
		90%,
		to {
			-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		}
		0% {
			opacity: 0;
			transform: translate3d(3000px, 0, 0);
		}
		60% {
			opacity: 1;
			transform: translate3d(-25px, 0, 0);
		}
		75% {
			transform: translate3d(10px, 0, 0);
		}
		90% {
			transform: translate3d(-5px, 0, 0);
		}
		to {
			transform: none;
		}
	}
	@keyframes shrink {
		0% {
			width: 98vw;
		}
		100% {
			width: 0;
		}
	}
	@media (min-width: 480px) {
		@keyframes animate-in {
			0%,
			60%,
			75%,
			90%,
			to {
				-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
				animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			}
			0% {
				opacity: 0;
				transform: translate3d(3000px, 0, 0);
			}
			60% {
				opacity: 1;
				transform: translate3d(-25px, 0, 0);
			}
			75% {
				transform: translate3d(10px, 0, 0);
			}
			90% {
				transform: translate3d(-5px, 0, 0);
			}
			to {
				transform: none;
			}
		}
		@keyframes shrink {
			0% {
				width: 40vw;
			}
			100% {
				width: 0;
			}
		}
	}
</style>
