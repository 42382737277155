import firebase from "firebase";
import "firebase/database";
import "firebase/functions";;
import { getGameSessionId, getParams } from "./utils";


const firebaseConfig = {
	apiKey: process.env.API_KEY,
	authDomain: process.env.AUTH_DOMAIN,
	databaseURL: process.env.DATABASE_URL,
	projectId: process.env.PROJECT_ID,
	storageBucket: process.env.STORAGE_BUCKET,
	messagingSenderId: process.env.MESSAGING_SENDER_ID,
	appId: process.env.APP_ID,
};

firebase.initializeApp(firebaseConfig);
export const functions = firebase.functions();

if (process.env.EMULATE) {
	const firebaseEmulators = {
		database: {
			host: "localhost",
			port: 9000,
		},
		functions: {
			host: "localhost",
			port: 5001,
		},
	};
	console.log("Automatically connecting Firebase SDKs to running emulators:");
	Object.keys(firebaseEmulators).forEach(function (key) {
		console.log("\t" + key + ": http://" + firebaseEmulators[key].host + ":" + firebaseEmulators[key].port);
	});
	if (firebaseEmulators.database && typeof firebase.database === "function") {
		firebase.database().useEmulator(firebaseEmulators.database.host, firebaseEmulators.database.port);
	}
	if (firebaseEmulators.firestore && typeof firebase.firestore === "function") {
		firebase.firestore().useEmulator(firebaseEmulators.firestore.host, firebaseEmulators.firestore.port);
	}
	if (firebaseEmulators.functions && typeof firebase.functions === "function") {
		firebase.functions().useEmulator(firebaseEmulators.functions.host, firebaseEmulators.functions.port);
	}
	if (firebaseEmulators.auth && typeof firebase.auth === "function") {
		firebase.auth().useEmulator("http://" + firebaseEmulators.auth.host + ":" + firebaseEmulators.auth.port);
	}
} else {
	console.log(
		"To automatically connect the Firebase SDKs to running emulators, replace '/__/firebase/init.js' with '/__/firebase/init.js?useEmulator=true' in your index.html"
	);
}

let roundValue = 1;
let serverTimeOffset = 0;

export const getRoundValue = () => {
	return roundValue;
};
export const getServerTimeOffset = () => {
	return serverTimeOffset;
};
const userId = getParams("userId");
export const dbRoot = firebase.database().ref("wos");
export const currentWordListRef = firebase.database().ref("wos").child("currentWordList");
export const dbGameSession = dbRoot.child(getGameSessionId());
export const dbUsers = dbGameSession.child("users");
export const dbGameSessionRoundValue = dbGameSession.child("roundValue");
export const dbGameSessionRounds = dbGameSession.child("rounds");
export const dbGameSessionRound = () => dbGameSessionRounds.child(`${getRoundValue()}`);
export const dbData = () => dbGameSessionRound().child("data");
export const dbUserDataKey = () => dbData().child(userId);
export const dbUserData = () => dbUserDataKey().child("info");
export const dbPage = () => dbGameSessionRound().child("page");
export const dbHost = dbGameSession.child("host");
export const dbThemeItem = dbGameSession.child("themeItem");
export const dbUser = dbUsers.child(userId);
export const dbTheme = () => dbGameSession.child("theme");
export const setRoundValue = val => (roundValue = val);
export const dbGameMode = () => dbGameSessionRound().child("data").child("mode");
export const dbGameWords = () => dbData().child("words");
export const dbGameUserScore = () => dbGameSessionRound().child("data").child("users").child(userId).child("score");
export const dbGameCardTicket = () =>
	dbGameSessionRound().child("data").child("users").child(userId).child("cardTicket");
export const dbGameBalls = () => dbGameSessionRound().child("data").child("balls");
export const dbGameEvents = () => dbGameSessionRound().child("data").child("users").child(userId).child("events");
export const dbCountdownTimer = () => dbGameSessionRound().child("countDownTimer");
export const dbGameTimer = () => dbGameSessionRound().child("gameTimer");
export const dbGameUserData = () => dbGameSessionRound().child("data").child("users");
export const dbGameLeaderboardRef = () => dbGameSessionRound().child("leaderboard");
export const dbAnnouncements = () => dbData().child("announcements");
export const dbHostAction = () => dbGameSessionRound().child("hostAction");

export const user = {
	id: getParams("userId"),
	userName: getParams("userName"),
	profilePicture: getParams("userProfilePicture"),
};

const connectedRef = firebase.database().ref(".info/connected");
connectedRef.on("value", snap => {
	if (snap.val() === true) {
		console.log("connected!");
		dbUser.update({
			online: true,
		});
		dbUser.onDisconnect().update({
			online: firebase.database.ServerValue.TIMESTAMP,
		});
	} else {
		console.log("user is offline please reload");
	}
});

firebase
	.database()
	.ref(".info/serverTimeOffset")
	.on("value", snapshot => {
		serverTimeOffset = snapshot.val();
	});

//listens for events on firebaseKey ref and calls callback
export function listenOnFirebaseKey(firebaseKey, callback, notExitCallback) {
	Promise.resolve(roundTimeValuePromise).then(() => {
		firebaseKey.on("value", snap => {
			if (snap.exists()) {
				callback(snap.val());
			} else {
				notExitCallback && notExitCallback();
			}
		});
	});
}

const roundTimeValuePromise = new Promise(resolve => {
	dbGameSessionRoundValue.once("value").then(snap => {
		if (!snap.exists()) {
			roundValue = 1;
		} else {
			roundValue = snap.val();
		}
		resolve(0);
	});
});

dbGameSessionRoundValue.once("value", snap => {
	if (!snap.exists()) {
		dbGameSessionRoundValue.set(1);
		roundValue = 1;
		return;
	}
	roundValue = snap.val();
});

dbGameSessionRoundValue.on("value", snap => {
	if (!snap.exists()) {
		dbGameSessionRoundValue.set(1);
		roundValue = 1;
		return;
	}
	roundValue = snap.val();
});

dbUser.update(user);
