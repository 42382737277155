<script>
  import { onDestroy, beforeUpdate, afterUpdate } from "svelte";
  import { onMount } from "svelte/internal";
  import Alphabet from "../components/Alphabet.svelte";
  import WordCard from "../components/WordCard.svelte";
  import GameHeader from "../components/GameHeader.svelte";
  import InfoPrompt from "../components/InfoPrompt.svelte";
  import Timer from "../components/Timer.svelte";
  import CircleTimer from "../components/CircleTimer.svelte";
  import { playSound } from "../services/audio";
  import { ExitModalScreen } from "da-components/v0";
  import {
    functions,
    user,
    dbAnnouncements,
    dbGameWords,
    dbGameTimer,
    dbGameMode,
    listenOnFirebaseKey,
    dbGameUserScore,
    getRoundValue,
    currentWordListRef,
  } from "../services/database";
  import {
    getGameSessionId,
    changeAnnouncement,
    createCoinAnimation,
    dictionary,
    constructLocalAnnouncement,
    wrongCallFunction,
    stringCanBeFormed,
  } from "../services/utils";
  import { endRound } from "../services/dbUtils";
  import Coin from "../components/Coin.svelte";
  import { secondsLeft } from "../services/dbUtils";
  import {
    letterStore,
    wordStore,
    wordListStore,
    userCurrentScoreStore,
    announcementStore,
    coinAnimationStore,
    exitModalStore,
    gameTipsStore,
    firstTimeRandomLetters,
    showfirstTimeRandomLettersTooltip,
  } from "../store";

  export let isHost;
  let interval;
  let timeLeft;
  let minLetters;
  let dbGameWordsRef, dbGameTimerRef, dbAnnouncementsRef;
  let infoPrompt = false;
  let infoPromptTimerRef;
  let letterToolTip = false;
  let wrongCallToolTip = false;
  let initialLoad;
  let word;
  let wrongCall = false;
  let timer321played = false;
  let disabled = false;
  let firstWrongCall = true;
  let coinsList = [];
  let refreshTime;
  let info = "";
  let gameEnded;
  let div;
  let autoscroll;
  let mode;
  let letterInput;
  // let firstTimeRandomLetters = false;
  let endGamePlayed = false;
  let newLetters;

  $: isTipsOn = $gameTipsStore;
  $: {
    if (mode) {
      minLetters = mode === "easy" ? 3 : 4;
      // refreshTime = mode === "easy" ? 15 : 10;
    }
  }

  $: letterStoreArray = $letterStore;

  const unsubscribeCoinAnimationStore = coinAnimationStore.subscribe((val) => {
    coinsList = val;
  });

  const checkProfanity = functions.httpsCallable("checkProfanity");

  const launchInfoPrompt = (prop) => {
    if (isTipsOn) {
      infoPrompt = true;
      clearTimeout(infoPromptTimerRef);
      infoPromptTimerRef = setTimeout(() => {
        infoPrompt = false;
        info = "";
      }, 5000);
    }
  };

  const wrongCallAnimate = () => {
    wrongCall = true;
    playSound("WRONG");
    setTimeout(() => {
      wrongCall = false;
    }, 1500);
  };

  const handleSubmit = () => {
    letterInput.focus();

    disabled = true;

    let parentString = "";
    letterStoreArray.forEach((el) => {
      parentString += el.alphabet;
    });

    const string = word?.trim().toUpperCase();

    word = "";

    // * check for nsfw words
    checkProfanity({
      word: string,
    })
      .then(() => {
        if (!dictionary.check(string)) {
          wrongCallAnimate();
          disabled = false;

          if (
            wrongCallFunction({
              type: "NOT_ENGLISH",
              stringLength: string.length,
              string,
              parentString,
            })
          ) {
            if (localStorage.getItem("firstWrong") === "true") {
              wrongCallToolTip = true;
              localStorage.setItem("firstWrong", false);
              setTimeout(() => {
                wrongCallToolTip = false;
              }, 5000);
              return;
            }
            if ($userCurrentScoreStore - string.length >= 0) {
              changeAnnouncement(
                {
                  message: "Wrong Word! That cost some coins.",
                  createdAt: Date.now(),
                  color: "red",
                },
                1000
              );
              dbGameUserScore().transaction((dbScore) => {
                return dbScore - string.length;
              });
              createCoinAnimation(false, string.length);
            }
          }
          return;
        }
        if (string.length < minLetters || string.length > 7) {
          wrongCallAnimate();
          console.log(
            `should be more than ${minLetters} and less than equal to 7`
          );
          wrongCallFunction({
            type: "NOT_MET_CONDITION",
            stringLength: string.length,
            mode,
          });
          disabled = false;
          return;
        }

        if (!stringCanBeFormed(parentString, string)) {
          wrongCallAnimate();
          changeAnnouncement(
            {
              message: "Umm! Choose your letters right!",
              createdAt: Date.now(),
              color: "red",
            },
            1000
          );
          disabled = false;
          return;
        }

        const letterList = string.split("").map((el) => {
          return {
            alphabet: el,
            value: $letterStore.find((letter) => letter.alphabet === el)?.value,
          };
        });
        // caluculate score
        const score = letterList.map((el) => el.value).reduce((a, b) => a + b);

        if (string.length === 7) {
          score = 2 * score;
        }

        currentWordListRef.child(string).set(
          {
            userName: user.userName,
            profilePicture: user.profilePicture,
            word: string,
            letterList,
            parentString,
            score,
            userId: user.id,
            roomIdSessionId: getGameSessionId(),
            roundId: getRoundValue(),
          },
          (error) => {
            if (error) {
              console.log("Data could not be saved." + error);
              console.log(error.message);
              wrongCallAnimate();
              wrongCallFunction({ type: "ALREADY_MADE" });
              disabled = false;
            } else {
              playSound("CORRECTWORD");
              disabled = false;
              createCoinAnimation(true, score);
              constructLocalAnnouncement(string.length, mode);
            }
          }
        );
      })
      .catch((e) => {
        wrongCallAnimate();
        if (e.message === "Bad Word") {
          wrongCallFunction({ type: "BAD_WORD" });
        }
        disabled = false;

        throw new Error("Bad Word");
      });
  };

  beforeUpdate(() => {
    autoscroll =
      div && div.offsetHeight + div.scrollTop > div.scrollHeight - 20;
    if (document.hasFocus()) {
      if (letterInput) {
        letterInput.focus();
      }
    }
  });

  afterUpdate(() => {
    if (autoscroll) div.scrollTo(0, div.scrollHeight);
    if (document.hasFocus()) {
      if (letterInput) {
        letterInput.focus();
      }
    }
  });

  onMount(() => {
    localStorage.setItem("firstWrong", true);

    const firstTime = localStorage.getItem("firstTime");

    if (!firstTime) {
      if (isTipsOn) {
        initialLoad = true;
        localStorage.setItem("firstTime", true);
        setTimeout(() => {
          initialLoad = false;
        }, 5000);
      }
    }

    dbGameMode().once("value", (snap) => {
      if (!snap.exists() || snap.val() == null) {
        console.log("mode doesn't exist");
        return;
      }
      mode = snap.val();
      minLetters = mode === "easy" ? 3 : 4;
    });
    dbGameWordsRef = dbGameWords();

    listenOnFirebaseKey(dbGameWordsRef.child("selectedLetters"), (val) => {
      if (!$letterStore.length) {
        if (isTipsOn) {
          firstTimeRandomLetters.set(true);
        }
      }
      letterStore.set(val);
      newLetters = true;
      setTimeout(() => {
        newLetters = false;
      }, 10);
      playSound("NEWLETTERS");
    });

    listenOnFirebaseKey(dbGameWordsRef.child("refereshTime"), (val) => {
      console.log(val);
      refreshTime = val;
    });

    dbAnnouncementsRef = dbAnnouncements();
    listenOnFirebaseKey(dbAnnouncementsRef, (val) => {
      let allAnnouncements = Object.values(val);
      let latestAnnouncement = allAnnouncements[allAnnouncements.length - 1];

      if (latestAnnouncement.userId !== user.id) {
        changeAnnouncement(latestAnnouncement, 3000);
      }
    });
    listenOnFirebaseKey(dbGameWordsRef.child("wordlist"), (val) => {
      let wordList = [];
      let words = [];
      for (const key in val) {
        wordList.push(val[key]);
        words.push(val[key].word);
      }
      wordStore.set(wordList);
      div.querySelector("#lastEl").scrollIntoView();
      wordListStore.set(words);
      console.log(wordList);
    });
    dbGameUserScore().on("value", (snap) => {
      if (!snap.exists()) {
        dbGameUserScore().set(0);
        letterStore.set([]);
        wordListStore.set([]);
        userCurrentScoreStore.set(0);
        console.log($letterStore, $wordListStore);
        return;
      } else {
        userCurrentScoreStore.set(snap.val());
      }
      console.log(snap.val());
    });

    dbGameTimerRef = dbGameTimer();
    dbGameTimerRef.on("value", (snapshot) => {
      if (!snapshot.exists() || snapshot.val() === null) {
        return;
      }
      const { duration, startAt, ended } = snapshot.val();
      const timeL = secondsLeft(duration, startAt);
      timeLeft = timeL;
      clearInterval(interval);
      interval = setInterval(() => {
        const timeL = secondsLeft(duration, startAt);
        timeLeft = timeL;
        disabled = Math.floor(timeLeft) < 1 || ended;
        if (
          Math.floor(timeLeft) === 3 ||
          (Math.ceil(timeLeft) === 3 && !timer321played)
        ) {
          timer321played = true;
          playSound("TIMER321");
        } else if (Math.floor(timeLeft) <= 0) {
          playSound("TIMER321STOP");
        }
        if (timeL < 1 || ended) {
          gameEnded = true;
          if (!endGamePlayed) {
            playSound("ENDGAME");
            endGamePlayed = true;
          }
          clearInterval(interval);
        }
      }, 1000);
    });
  });

  onDestroy(() => {
    clearInterval(interval);
    clearTimeout(infoPromptTimerRef);
    dbGameWordsRef.off("value");
    dbGameTimerRef.off("value");
    dbGameUserScore().off("value");
    unsubscribeCoinAnimationStore();
    wordStore.set([]);
    wordListStore.set([]);
    letterStore.set([]);
    playSound("GAMESCREENTOLEADERBOARD");
  });
</script>

<div class="GameScreenRoot">
  <div class="GameHeaderWrapper">
    <GameHeader score={$userCurrentScoreStore} time={timeLeft} {isHost} />
  </div>
  <div class="gameSection">
    <div class="alphabetSection">
      {#each $letterStore as item}
        <Alphabet {newLetters} {item} size="big" />
      {/each}
      {#if $letterStore}
        <div on:click={launchInfoPrompt} class="timer">
          <Timer ended={gameEnded} totalTime={refreshTime} {newLetters} />
          <!-- <CircleTimer lettersArray={$letterStore} /> -->
        </div>
      {/if}
      <div class="content">
        {#if $showfirstTimeRandomLettersTooltip}
          <InfoPrompt
            class="letterInfo"
            info={`Letters get refreshed every ${refreshTime} seconds.`}
            emojiCode="💡"
          />
        {:else if infoPrompt}
          <InfoPrompt
            class="letterInfo"
            info={`Letters get refreshed every ${refreshTime} seconds.`}
            emojiCode="💡"
          />
        {/if}
      </div>
    </div>
    <div class="announcements">
      <p class={$announcementStore?.color || ""}>
        {$announcementStore.message}
      </p>
    </div>
    <div class="gameBoard">
      <div class="mainGameSection" bind:this={div}>
        <!-- {#if initialLoad}
						<div class="gameBoaredToolTip">
							<InfoPrompt info="Make dictionaty words from the presented letters. Get Started!" emojiCode="💡" />
						</div>
					{/if} -->

        {#if $wordStore.length === 0}
          <div class="empty-placeholder">
            <p>
              Make {minLetters} to 7 letter words, before the letters refresh!
            </p>
          </div>
        {/if}

        {#each $wordStore as item}
          <WordCard {item} />
        {/each}
        <div id="lastEl" />
        <div class="coinWrapper">
          {#each $coinAnimationStore as coin (coin.id)}
            <Coin
              correct={coin.correct}
              noOfCoins={coin.noOfCoins}
              id={coin.id}
            />
          {/each}
        </div>
      </div>
      <div class="inputSection">
        <form class="inputWrapper" on:submit|preventDefault={handleSubmit}>
          <input
            bind:this={letterInput}
            disabled={disabled || timeLeft <= 0}
            spellcheck="true"
            class:wrongCall
            bind:value={word}
            placeholder="Enter your guess here"
          />
        </form>
        <button
          disabled={disabled || timeLeft <= 0}
          class={wrongCall ? "wrongButton" : "rightButton"}
          on:click={handleSubmit}
        />
        <div class="wrongToolTipWrapper">
          {#if wrongCallToolTip}
            <InfoPrompt
              class="wrongToolTip"
              info={`Be Careful! You get penalised for guessing incorrect words.`}
              emojiCode="💡"
            />
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

{#if $exitModalStore === true}
  <div class="exitModalWrapper">
    <ExitModalScreen
      onMouseDown={() => playSound("CLICK")}
      startNew={() => {
        endRound();
        exitModalStore.set(false);
      }}
      goBack={() => {
        exitModalStore.set(false);
      }}
    />
  </div>
{/if}

<style>
  .empty-placeholder {
    color: #a1a1a1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 250px;
    text-align: center;
  }

  .empty-placeholder p {
    font-size: 20px;
    font-family: Pally-Regular;
  }

  .mainGameSection :global(.gameBoaredToolTip) {
    position: absolute;
    min-width: 200px;
    left: 50%;
    top: 50%;
    bottom: 50%;
  }

  .content :global(.letterInfo) {
    transform: translateX(5px);
    transform: translateY(40px);
  }

  .wrongToolTipWrapper :global(.wrongToolTip) {
    min-width: 200px;
  }

  .GameScreenRoot {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: #4e4741;
    background-image: url(/images/backgrounds/wooden-background.svg);
    background-position: center;
    background-size: cover;
  }
  .GameHeaderWrapper {
    z-index: 2;
    max-height: 20vh;
  }

  .exitModalWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
  }

  .single-coin-wrapper,
  .multiple-coin-wrapper {
    position: absolute;
    margin-left: 0;
  }

  @keyframes fadeAndScale {
    from {
      opacity: 0;
      transform: scale(0.1, 0.1);
    }
    to {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  .announcements {
    max-width: 100vw;
    min-height: 25px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    white-space: nowrap;
  }

  .announcements p {
    animation-duration: 0.3s;
    animation-name: fadeAndScale;
    animation-timing-function: linear;
  }
  .gameSection {
    min-height: 80vh;
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .alphabetSection {
    position: relative;
    height: 120px;
    min-width: 100vw;
    background-image: url(/images/alphabet-plank.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  .gameBoard {
    height: calc(10vw + 200px);
    width: calc(50vw + 300px);
    position: relative;
    background: #d9d9d9;
    outline: dashed;
    outline-offset: -20px;
    outline-color: #d4895b;
    outline-width: 3.5px;
    background-repeat: no-repeat;
  }

  .gameBoardToolTip {
    right: 40%;
  }
  .mainGameSection {
    margin-top: 20px;
    margin-bottom: 30px;
    height: calc(100% - 60px);
    padding: 20px;
    overflow-y: scroll;
  }

  .mainGameSection::-webkit-scrollbar {
    width: 0.3rem;
  }
  .mainGameSection::-webkit-scrollbar-thumb {
    background: #473f36;
  }
  .inputSection {
    margin-right: auto;
    height: fit-content;
    width: fit-content;
    display: flex;
    position: absolute;
    bottom: -10%;
    left: 30%;
    right: 40%;
    top: 90%;
  }

  .inputSection button {
    height: 50px;
    width: 50px;
    margin: 20px 0 10px 10px;
    align-self: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 0;
  }

  .rightButton {
    background: url(/images/enter-button.svg);
  }
  .wrongButton {
    background: url(/images/wrong-input.svg);
  }
  .inputWrapper {
    background-image: url(/images/input-box.svg);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    min-width: 30vw;
    justify-content: center;
    font-size: 20px;
  }

  .coinWrapper {
    position: absolute;
    bottom: -10%;
    left: 60%;
  }
  .inputWrapper input {
    width: 80%;
    margin: 10px 5px 2px 25px;
    min-height: 45px;
    text-transform: uppercase;
    color: #b67f56;
    font-family: Cubano;
    letter-spacing: 5px;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: "Pally";
    color: #b67f56;
    font-weight: 300;
    letter-spacing: normal;
    text-transform: none;
  }
  .wrongCall {
    background: #b67373;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both 0.1s;
    color: #ffffff !important;
  }

  .wrongCall::-webkit-input-placeholder {
    color: #ffffff;
  }

  .red {
    color: #fc5a5a;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @media only screen and (max-width: 1200px) {
    .announcements {
      min-height: 20px;
      font-size: 16px;
    }

    .inputWrapper {
      min-width: 40vw;
    }
    .inputSection {
      height: fit-content;
      width: fit-content;
      display: flex;
      position: absolute;
      /* left: 0%; */
    }

    .gameBoard {
      background-size: cover;
      background-repeat: no-repeat;
    }

    /* needs better position */
    .content {
      position: absolute;
      top: -80%;
    }
    .content :global(.letterInfo) {
      transform: translateX(-55px);
      transform: translateY(-55px);
    }

    .wrongToolTipWrapper {
      position: absolute;
      bottom: -5%;
      right: 30%;
      left: 50%;
    }
  }

  @media only screen and (max-width: 800px) {
    /* .inputSection {
			left: 10%;
		} */
    .inputWrapper {
      min-width: 40vw;
    }
  }

  @media only screen and (max-width: 600px) {
    .inputSection {
      left: 10%;
    }
    .inputWrapper {
      min-width: 80vw;
    }
  }

  @media only screen and (max-height: 728px) {
    .alphabetSection {
      margin-top: 50px;
    }
    .gameBoard {
      height: calc(6vw + 150px);
    }
    .inputSection {
      top: 75%;
    }
  }

  @media only screen and (max-width: 480px) {
    .announcements {
      display: none;
    }
    .inputSection {
      height: fit-content;
      width: fit-content;
      display: flex;
      position: absolute;
      left: 0%;
    }

    .alphabetSection {
      background-size: cover;
      transform: scale(0.8);
    }

    .gameBoard {
      height: calc(5vw + 250px);
      transform: scale(0.65);
    }

    /*needs better position */
    .content {
      position: absolute;
    }
    .content :global(.letterInfo) {
      transform: translateX(-55px);
      transform: translateY(55px);
    }

    .inputSection, .mainGameSection {
      transform: scale(0.9);
    }

    .inputSection {
      top: 88%;
    }

    .inputWrapper {
      min-width: 120vw;
    }
  }
</style>
