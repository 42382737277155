import { Typo } from "./typo";
import { announcementStore, coinAnimationStore } from "../store";
import { v4 as uuidv4 } from "uuid";

export let dictionary = new Typo("en_US", false, false, { dictionaryPath: "dictionaries" });

const params = new URLSearchParams(window.location.search);

let announcementTimer = "";

export function getParams (name) {
	return '' + params.get(name);
};
export const getGameSessionId = function () {
	return `${getParams("roomId")}+${getParams("sessionId")}`;
};


export const getParsedValue = val => {
	try {
		if (val === null) {
			val = true;
		} else {
			val = JSON.parse(val);
		}
		return val;
	} catch (err) {
		console.log(err);
	}
};


export const wrongCallFunction = ({ type, stringLength, string, parentString, mode}) => {
	
	switch (type) {
		case "NOT_ENGLISH":

			if (stringCanBeFormed(parentString, string)) {
				return true;
			}
				changeAnnouncement({ message: "Umm! Choose your letters right!", createdAt: Date.now(), color: "red" }, 1000);
			break;
		case "NOT_MET_CONDITION":
			
			if (stringLength === 3 && mode !== 'easy') {
				changeAnnouncement({ message: "Not so easy! Use 4 or more letters.", createdAt: Date.now(), color: "red" }, 1000);
			}
			break;
		case "BAD_WORD":
			changeAnnouncement({ message: "Please avoid using bad word!", createdAt: Date.now(), color: "red" }, 1000);
			break;
		case "INVALID_ARG":
			changeAnnouncement({ message: "Umm! Choose your letters right!", createdAt: Date.now(), color: "red" }, 1000);
			break;
		case "ALREADY_MADE":
			changeAnnouncement({ message: "Oops! Word already made!", createdAt: Date.now(), color: "red" }, 1000);
			break;
		default:
			break;
	}
}


export const constructLocalAnnouncement = ( stringLength, mode ) => {
	switch (stringLength) {
		case 7:
			changeAnnouncement({ message: "Wohho! 7 letter word bonus!", createdAt: Date.now(), color: "white" }, 1000);
			break;
		case 4:
			changeAnnouncement({ message: "Great Going!", createdAt: Date.now(), color: "white" }, 1000);
			break;
		case 3:
			changeAnnouncement({ message: "Good Stuff", createdAt: Date.now(), color: "white" }, 1000);
			break;
	
		default:
			break;
	}
}

export const changeAnnouncement = (latestAnnouncement, duration = 1000) => {
	if (latestAnnouncement && Date.now() > latestAnnouncement.createdAt + duration) {
		return;
	}
	announcementStore.set(latestAnnouncement);
	let runTimeoutFor = latestAnnouncement.createdAt + duration - Date.now();
	if (runTimeoutFor >= 0) {
		if (announcementTimer) {
			clearTimeout(announcementTimer);
		}
		announcementTimer = setTimeout(() => {
			announcementStore.set({
				message: "",
				createdAt: 1,
			});
		}, runTimeoutFor);
	}
};


export function stringCanBeFormed(parent, child) {
    
    const parentObj = {};

    [...parent].forEach(element => {
        if (parentObj[element]) {
            parentObj[element]++
        } else {
            parentObj[element] = 1;
        }
       
    });

    const childObj = {};

    for (const element of child) {
        if (!parentObj[element]) {
            return false;
        }
        if (childObj[element]) {
            childObj[element]++
            if (childObj[element] > parentObj[element]) {
                return false;
            }
        } else {
            childObj[element] = 1;
        }
    }

    return true;
}



export const createCoinAnimation = (correct, noOfCoins) => {
	let id = uuidv4();
	coinAnimationStore.update(old => {
		return [
			...old,
			{
				correct,
				noOfCoins,
				id: id,
			},
		];
	});
};




export const delay = (ms) =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, ms);
	});

export const fancyTimeFormat = (duration) => {
	// Hours, minutes and seconds
	var mins = Math.floor((duration % 3600) / 60);
	var secs = Math.floor(duration % 60);

	// Output like "1:01" or "4:03:59" or "123:03:59"
	var ret = '';
	ret += '0' + mins + ':' + (secs < 10 ? '0' : '');
	ret += '' + secs;
	return ret;
};

export let preLoadAssets = async (page) => {
	if (page === 'GAME') {
		let img1 = new Image();
		let img2 = new Image();
		let img3 = new Image();
		let img4 = new Image();
		let img5 = new Image();
		let audio1 = new Audio("/music/NewLetters.wav");
		let audio2 = new Audio("/music/WosToLeaderboard.wav");
		let audio3 = new Audio("/music/CorrectWord.wav");
		let audio4 = new Audio("/music/Wrong.wav");
		let audio5 = new Audio("/music/Timer321.mp3");
		img1.src = "/images/alphabet-box.svg";
		img2.src = "/images/main-gameboard.svg";
		img3.src = "/images/wos-header-logo.svg";
		img4.src = "/images/alphabet-plank.svg";
		img5.src = "/images/wrong-input.svg";
	} else if (page === "CHOOSE_MODE") {
		let img1 = new Image();
		let img2 = new Image();
		img1.src = "/images/easyTicket.svg";
		img2.src = "/images/hardTicket.svg";
	}
};





