<script lang="ts">
    import { beforeUpdate } from "svelte";
    export let size;
    export let item;
    export let newLetters;
    let animate = false;

    beforeUpdate(() => {
    if (newLetters) {
        animate = true;
        setTimeout(() => {
            animate = false;
        }, 1000);

     
    }

	});
</script>


<div class="letter {size === 'big' ? 'big' : 'small'}" class:animate>
    <h1>{item.alphabet}</h1>
    <span>{item.value}</span>
</div>

<style>
    .letter {
        background: url(/images/alphabet-box.svg);
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        

    }
    .big {
        width: calc(1vw + 42px);
        height: calc(1vw + 45px);
        margin: 10px;
    }

    .animate {
        animation: spaceboots 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both 0.1s;
    }


    .small {
        width: 35px;
        height: 37px;
        margin: 5px;
    }


    h1 {
        color: #B67F56;
        font-size: calc(25px + 0.4vw);
        font-family: Cubano;
    }

    .small h1 {
        font-size: 1rem;
    }

    span {
        color: #551A1280;
        position: absolute;
        right: 5px;
        bottom: 5px;
        font-family: Cubano;


    }

    .small span {
        color: #551A1280;
        position: absolute;
        right: 2px;
        bottom: 2px;
        font-size: small;
        font-family: Cubano;
    }

    @keyframes spaceboots {
	    0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
	    10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
	    20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
	    30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
	    40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
	    50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
	    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
	    70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
	    80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
	    90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
	    100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
    }

    @keyframes shake {
		10%,
		90% {
			transform: translate3d(-1px, 0, 0);
		}
		20%,
		80% {
			transform: translate3d(2px, 0, 0);
		}
		30%,
		50%,
		70% {
			transform: translate3d(-4px, 0, 0);
		}
		40%,
		60% {
			transform: translate3d(4px, 0, 0);
		}
	}


    @media only screen and (max-width: 1350px) {
        .big {
            width: calc(2vw + 42px);
            height: calc(2vw + 45px);
            margin: 10px;
        }

    }

    @media only screen and (max-width: 646px) {
        .big {
            width: calc(3.5vw + 32px);
            height: calc(3.5vw + 35px);
            margin: 5px;
        }

    }

    @media only screen and (max-width: 480px) {
        .big {
            width: calc(6vw + 22px);
        height: calc(6vw + 25px);
        margin: 5px;
        }

        h1 {
            font-size: calc(18px + 0.4vw);
        }

        span {
            font-size: 10px;
        }

    }

    @media only screen and (max-width: 450px) {
        .big {
            width: calc(4vw + 22px);
            height: calc(4vw + 25px);
            margin: 5px;
        }

    }
	
</style>
