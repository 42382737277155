import { writable } from "svelte/store";

export const sound = writable(true);
export const usersStore = writable([]);
export const notification = writable();
export const cardStore = writable();
export const letterStore = writable([]);
export const wordStore = writable([]);
export const wordListStore = writable([]);
export const coinAnimationStore = writable([]);
export const coinCounterBindStore = writable();
export const exitModalStore = writable(false);
export const userCurrentScoreStore = writable(0);
export const gameTipsStore = writable(true);
export const firstTimeRandomLetters = writable(false);
export const showfirstTimeRandomLettersTooltip = writable(false);




export const announcementStore = writable({
	message: "",
	createdAt: 1,
});
