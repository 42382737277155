<script lang="ts">
    	import Alphabet from "./Alphabet.svelte";
        import { ProfilePicture } from "da-components";
        export let item;


</script>


<div class="wordwrapper">
    <div>
        <ProfilePicture
        src={item.profilePicture}
        userName={item.userName}
        size='30'
        fontSize='20'
        />
    </div>
    <p>{item.userName.split(' ')[0]}</p>
    {#each item.letterList as  el}
        <Alphabet item={el} size="small" />
    {/each}
</div >

<style>
    .wordwrapper {
        /* height: 20px; */
        /* width: 20px; */
        background-color: #6F5E4E;
        display: inline-flex;
        align-items: center;
        padding : 7px;
        margin: 12px;
        border-radius: 50px;
        animation: shimmer 0.5s 2 forwards ;
        /* max-height: 200px; */
    }

    .wordwrapper div {
        padding-left: 3px;
    }

    @keyframes shimmer {
        from {background-color: #BA9D81;}
        to {background-color: #6F5E4E;}
    }
    .wordwrapper p {
        margin: 5px;
        font-family: 'Work Sans';
    }
    @media only screen and (max-width: 1200px) {
        .wordwrapper {
            margin-left: 30px;
			
	   }

    }

    @media only screen and (max-width: 500px) {
        .wordwrapper {
            margin-left: 50px;
			transform: scale(0.9);
	   }

    }
   
	
</style>