<script>
	import { playSound } from "../services/audio";
	import { TimerScreen, SplashScreen } from "da-components/v0";
	import { fade } from "svelte/transition";
	import { dbCountdownTimer, dbGameCardTicket, dbGameMode, dbPage, listenOnFirebaseKey } from "../services/database";
	import Header from "../components/Header.svelte";
	import { onDestroy, onMount } from "svelte";
	export let isHost;
	let chosenMode;
	let countDown;
	let dbCountdownTimerRef;

	onMount(() => {
		dbGameMode().once("value", snap => {
			if (!snap.exists) {
				chosenMode = "unknown";
				return;
			}
			chosenMode = snap.val();
		});
		dbCountdownTimerRef = dbCountdownTimer();
		listenOnFirebaseKey(dbCountdownTimerRef, val => {
			countDown = val;
			if (countDown === 3) {
				playSound("BIGCOUNTDOWN");
			}
		});
	});

	onDestroy(() => {
		playSound("TIMERTOWOS");
		playSound("BIGCOUNTDOWNSTOP");
		dbCountdownTimerRef.off("value");
	});
</script>

{#if countDown <= 3}

<div class="TimerRoot" in:fade={{ duration: 500 }}>
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
	<div class="TimerWrapper">
		{#if chosenMode && chosenMode.length > 0}
			<TimerScreen
				countDown={countDown ? countDown : ""}
				ribbonText={chosenMode[0].toUpperCase() + chosenMode.slice(1) + " Mode "}
				displayCounter={true}
			/>
		{/if}
	</div>
</div>
{:else}
	<SplashScreen
		svgSrc={"./images/backgrounds/wooden-background.svg"}
		bgColor={"#8A6C4D"}
		logoSrc={"./images/wos-splash-logo.svg"}
		subHeading={"⛴ Let your Words take you to Treasure ⚓️"}
	/>
{/if}

<style>
	.TimerRoot {
		width: 100%;
		height: 100%;
		display: flex;
		background: var(--base);
	}.TimerWrapper {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.headerWrapper {
		z-index: 2;
	}

	@media screen and (max-height: 500px) {
		.TimerWrapper {
			position: static;
			height: 100%;
			transform: translate(0, 0);
			margin-top: 100px;
		}
	}

</style>