<script>
	import { Header } from "da-components/v0";
	import { sound } from "../store";
	import { playSound } from "../services/audio";
	export let isHost = false;
	let isSoundOn = false;
	let hostRoundController = false;

	function toggleHostRoundController() {
		playSound("CLICK");
		hostRoundController = !hostRoundController;
	}

	async function endRound() {
		playSound("CLICK");
		await dbGameSessionRoundValue.transaction(count => {
			return count + 1;
		});
	}

	$: soundState = $sound;

	$: if (soundState === false) {
		playSound("MUTE");
		isSoundOn = false;
	} else {
		isSoundOn = true;
		playSound("UNMUTE");
	}

	const soundButtonHandler = isSoundOn => {
		// console.log("pressed", isSoundOn);
		if (isSoundOn === true) {
			isSoundOn = true;
			sound.update(() => true);
			playSound("CLICK");
		} else if (isSoundOn === false) {
			playSound("CLICK");
			isSoundOn = false;
			sound.update(() => false);
		}
	};
</script>

<Header gameNameSvg="/images/wos-header-logo.svg" {isHost} {soundButtonHandler} {isSoundOn} themeSupported={false} />

<style>
</style>
