<script lang="ts">
	export let info = "";
	export let emojiCode = "";
</script>

<div>
	{#if emojiCode.length > 0}
		<span>
			{emojiCode}
		</span>
	{/if}
	<p>{info}</p>
</div>

<style>
	div {
		z-index: 200;
		position: absolute;
		/* right: 0%; */
		/* top: 33%; */
		min-width: 200px;
		max-height: 200px;
		padding: 1rem;
		color: black;
		background: white;
		font-family: "Work Sans";
		font-size: 14px;
		border: 2px solid #392f5a;
		box-sizing: border-box;
		box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.3);
		border-radius: 12px;
		line-height: 130%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		z-index: 2000;
	}

	span {
		font-size: 25px;
	}

	p {
		text-align: left;
		color: black;
	}
</style>
