<script>
	import { onDestroy, onMount } from "svelte";
	import Header from "../components/Header.svelte";
	import { playSound } from "../services/audio";
	import {
		dbGameLeaderboardRef,
		dbGameSessionRoundValue,
		dbPage,
		getRoundValue,
		listenOnFirebaseKey,
		user,
	} from "../services/database";
	import { usersStore } from "../store";
	import { LeaderboardScreen } from "da-components/v0";
	import { Confetti } from "da-components";
	export let isHost;
	export let hostName = "";
	let userId = user.id;
	let namesAndScores = [];
	let usersList = [];
	let dbGameLeaderboardRefOff;
	let oneLineMessage = "";
	let confettiTimeOut;

	//this might need some change
	const handleNewGame = async () => {
		window?.DIVE_APP?.sendEvent({
			type: "playing-again",
			payload: {
				playinAgain: true,
			},
		});
		await dbGameSessionRoundValue.set(getRoundValue() + 1);
	};

	onMount(() => {
		dbGameLeaderboardRefOff = dbGameLeaderboardRef();
		listenOnFirebaseKey(dbGameLeaderboardRefOff.child("scores"), val => {
			namesAndScores = val;
			formatDataForLeaderboard();
		});
		Confetti.startSpawning();
		confettiTimeOut = setTimeout(function () {
			Confetti.stopSpawning();
			Confetti.removeCanvas();
		}, 5000);
	});
	onDestroy(() => {
		dbGameLeaderboardRefOff.off("value");
		clearTimeout(confettiTimeOut);
		Confetti?.stopSpawning();
		Confetti?.removeCanvas();
	});

	const formatDataForLeaderboard = () => {
		let position = 1;
		let current = 0;
		let myPos = 4;
		let sharedTop = false;
		while (current < namesAndScores.length) {
			let usersArray = namesAndScores
				.slice(current, namesAndScores.length)
				.filter(el => el.score === namesAndScores[current].score);
			usersArray = usersArray.map(el => {
				if (el.userId === userId) {
					myPos = position;
				}
				if (position === 1) {
					sharedTop = usersArray.length > 1;
				}
				return {
					name: $usersStore[el.userId].userName,
					score: el.score,
					src: $usersStore[el.userId].profilePicture,
					id: el.userId,
				};
			});
			current += usersArray.length;
			usersList[position - 1] = { position, usersArray: usersArray };
			position++;
		}
		//generate one line message here

		if (myPos === 1 && !sharedTop) {
			oneLineMessage = "Wohhoo! You Won 🔥";
		} else if (myPos > 1 && !sharedTop) {
			oneLineMessage = "That’s a Clear Win 🎉";
		} else if (myPos === 1 && sharedTop) {
			oneLineMessage = "You Won & it’s a Tie! 🤝";
		} else if (sharedTop) {
			oneLineMessage = "Damn! It’s a Tie 🤝 Well Played";
		} else {
			oneLineMessage = "Let’s keep going 💪";
		}

		return usersList;
	};
</script>

<div class="leaderboardRoot">
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
	<div class="leaderboardWrapper">
		<LeaderboardScreen
			{isHost}
			continueHandler={handleNewGame}
			users={usersList}
			onMouseDown={() => playSound("CLICK")}
			nextRoundOrPlayAgain="Play Again"
			{userId}
			{oneLineMessage}
			{hostName}
		/>
	</div>
</div>

<style>
	.leaderboardRoot {
		width: 100%;
		height: 100%;
		display: flex;
		background-image: url("/images/backgrounds/wooden-background.svg");
		background-position: center;
		background-color: var(--base);
		background-size: cover;
	}

	.leaderboardWrapper {
		width: 100%;
		font-size: 24px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.headerWrapper {
		z-index: 2;
	}

	@media screen and (max-height: 650px) {
		.leaderboardWrapper {
			position: static;
			height: 100%;
			transform: translate(0, 0);
			margin-top: 5rem;
		}
	}
</style>
