import { getServerTimeOffset, dbGameTimer, dbHostAction, dbGameSessionRoundValue } from './database';

export const secondsLeft = (duration, startAt) => {
	return (
		((duration - 5) * 1000 -
			(Date.now() - startAt - getServerTimeOffset())) /
		1000
	);
};

export async function endRound() {
	await dbGameTimer().child("ended").set(true);
	await dbHostAction().set({
		action: "End Round",
		time: Date.now(),
	});
	await dbGameSessionRoundValue.transaction(count => {
		return count + 1;
	});
}