import * as Sentry from "@sentry/browser";
import App from "./App.svelte";
import "@sentry/browser";
if (!process.env.EMULATE &&
    (process.env.APP_ENV === "prod" || process.env.APP_ENV === "dev")) {
    console.log("sentry connected");
    Sentry.init({
        dsn: "https://24eabfcb1206490fb9f6d1c285643da0@o422923.ingest.sentry.io/6229613",
        environment: process.env.APP_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
if (!process.env.EMULATE && (process.env.APP_ENV === "prod" || process.env.APP_ENV === "dev")) {
    console.log("sentry connected");
    Sentry.init({
        dsn: "https://24eabfcb1206490fb9f6d1c285643da0@o422923.ingest.sentry.io/6229613",
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
const app = new App({
    target: document.body,
    props: {},
});
export default app;
