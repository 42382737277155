<script lang="ts">
import { onDestroy,  beforeUpdate } from "svelte";
import { letterStore,  gameTipsStore, firstTimeRandomLetters, showfirstTimeRandomLettersTooltip } from "../store";


const FULL_DASH_ARRAY = 283;

export let totalTime;
let timePassed = 0;
let timeLeft = totalTime;
let timerInterval = null;
export let newLetters;
export let ended;

$: isTipsOn = $gameTipsStore;

  timerInterval = setInterval(() => {
    timePassed = timePassed += 1;
    timeLeft = totalTime - timePassed;
    setCircleDasharray();
    if (timeLeft === 2) {
				if (isTipsOn && $firstTimeRandomLetters) {
          showfirstTimeRandomLettersTooltip.set(true);
						setTimeout(() => {
							showfirstTimeRandomLettersTooltip.set(false);
              firstTimeRandomLetters.set(false);
						}, 5000);
						
					
				}

    }
  }, 1000);

  beforeUpdate(() => {
    if (newLetters) {
      console.log('newLetters');
      
      timePassed = 0;
      timeLeft = totalTime;
      document.getElementById("base-timer-path-remaining").setAttribute("stroke-dasharray", '0 -10');
      setCircleDasharray(newLetters);
    }
    if (ended) {
        clearInterval(timerInterval);
    }

	});



function calculateTimeFraction() {
  const rawTimeFraction = timeLeft / totalTime;
  return rawTimeFraction - (1 / totalTime) * (1 - rawTimeFraction);
}

function setCircleDasharray(newLetters) {
  let circleDasharray;
  if (newLetters) {
     circleDasharray = `0 -10`;
  } else {
    circleDasharray = `${(
    FULL_DASH_ARRAY - (calculateTimeFraction() * FULL_DASH_ARRAY)
    ).toFixed(0)} 283`;
  }
  
  document
    .getElementById("base-timer-path-remaining")
    .setAttribute("stroke-dasharray", circleDasharray);
}


onDestroy(() => {
		clearInterval(timerInterval);
});


</script>


<div class="base-timer">
  <div class="logo"></div>
  <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g class="base-timer__circle">
      <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
      <path
        id="base-timer-path-remaining"
        stroke-dasharray="-10"
        class="base-timer__path-remaining"
        d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
      ></path>
    </g>
  </svg>
</div>

<style>

.base-timer {
  position: relative;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: 10px;
}

.logo {
    background: url(/images/RefreshInfo.svg);
    position: absolute;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    margin: auto;
    background-position: center;
}
.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill:  none;
  stroke:  none;
}

.base-timer__path-elapsed {
  stroke: none;
  fill: none;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(-270deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}


</style>